// 用户信息的store模块
import { reqLogin, reqUserInfo } from '@/api/user'
import { GET_TOKEN, SET_TOKEN, REMOVE_TOKEN } from '@/utils/token'
// 引入路由
import { constanRouter } from "@/router/router"
export default {
  namespaced: true,
  state: {
    menuRouters: constanRouter, //仓库存储菜单需要的路由
    token: GET_TOKEN(), // 用户token
    spread: {}, // 用户信息
  },
  actions: {
    // 用户登录的方法
    async userLogin ({ state }, data) {
      // 登录请求
      const result = await reqLogin(data)
      if (result.code == 200) {
        state.token = result.data.access_token // 断言
        // 本地存储持久化存储
        SET_TOKEN(result.data.access_token)
        // 能保证当前的async函数返回一个成功的promise
        return "ok"
      } else {
        return Promise.reject(new Error(result.data))
      }
    },
    // 获取用户信息
    async userInfo ({ state }) {
      const result = await reqUserInfo()
      if (result.code == 200) {
        state.spread = {
          spread_name: result.data.spread_name,
          tel: result.data.tel,
          state: result.data.state,
        }
        return 'ok'
      } else {
        return Promise.reject(new Error(result.message))
      }
    },
    // 退出登录
    userLogout ({ state }) {
      console.log(111111111);
      
      state.token = ''
      state.spread = null
      REMOVE_TOKEN()
    }
  },
  mutations: {
  }
}