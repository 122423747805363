import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import msg from './assets/js/message'
import common from './assets/js/common'
import pagination from "./components/pagination"
import search from "./components/search"
import attachmentSelectBtn from '@/components/attachmentSelectBtn'
import loadPage from '@/components/loadPage'  //加载
import download from '@/components/download' // 下载中心
import importTableTools from '@/components/importTableTools' // 导入
import readExcel from '@/components/readExcel'
Vue.use(ElementUI)

Vue.component("pagination", pagination)
Vue.component("search", search)
Vue.component("attachmentSelectBtn", attachmentSelectBtn)
Vue.component("loadPage", loadPage)
Vue.component("download", download)
Vue.component("importTableTools", importTableTools)
Vue.component("readExcel", readExcel)
// 引入路由鉴权
import "./permisstion"

// 引入自动更新
import '@/utils/autoRefresh.js'

Vue.prototype.$msg = msg // 全局挂载
Vue.prototype.$common = common // 全局挂载

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})